<template>
  <div class="row">
    <div class="col-md-6 pt-4 ps-8">
      <h3>{{ denominazione_impianto }}</h3>
    </div>
    <div class="col-md-6 text-end">
      <router-link
        :to="`/enti-aggregati/dettaglio-ente/impianti-ente/omologazioni-impianto/${id_societa}/${id_impianto}`"
        class="btn btn-light text-gray-700 fs-6 text-start"
      >
        <i class="bi bi-arrow-left fs-6 text-gray-700"></i> Lista omologazioni
        impianto
      </router-link>
    </div>
  </div>
  <AddOmologazione
    :id_imp="id_impianto"
    :id_omo="id_omo"
    :societa_nome="ente.denominazione"
    :fromSocieta="true"
    :fromEnte="true"
  />
</template>

<script>
import { ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import AddOmologazione from "@/components/components-fit/impianti/AddOmologazione.vue";
import { useRoute } from "vue-router";

export default {
  components: { AddOmologazione },
  props: {
    ente: {
      type: Object,
    },
  },
  setup() {
    const route = useRoute(); // prende id
    const id_societa = ref(route.params.id);
    const id_impianto = ref(route.params.id_impianto);
    const id_omo = ref(route.params.id_omo);
    const denominazione_impianto = ref(route.params.denominazione_impianto);
    onMounted(() => {
      setCurrentPageBreadcrumbs("Nuova Omologazione", [
        "Lista Societa",
        "Dettaglio Societa",
        "Impianti Società",
      ]);
    });
    return { id_societa, id_impianto, id_omo, denominazione_impianto };
  },
};
</script>

<style></style>
